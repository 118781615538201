import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import ReactTable from "../../components/ReactTable/ReactTable";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Dvr from "@material-ui/icons/Dvr";
import axios from "axios";
import { useForm } from "../../hooks/useForm/useForm";
import { PurchaseOrderNew } from "./PurchaseOrderNew";

const useStyles = makeStyles(styles);

const PurchaseOrderList = () => {
  let endpoint = "/api/bff/document/";
  let [list, setList] = React.useState([]);
  let [open, setOpen] = useState(false);
  let [numOrderState] = useForm("");
  let [netAmountState] = useForm("");
  let [numOfficeGuideState] = useForm("");
  let [deliveryDateState] = useForm("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [
    { numPurchase, netAmount, numOfficeGuide, deliveryDate, web },
    handleInputChange,
  ] = useForm({
    numOrder: "",
    netAmount: "",
    deliveryDate: "",
    numOfficeGuide: "",
    web: "",
  });
  const classes = useStyles();
  let { token } = useSelector((state) => state.auth);
  //const { id_document, deleted } = useSelector((state) => state.purchase);
  React.useEffect(async () => {
    await axios
      .get(endpoint, {
        headers: { "authorization-token": token },
      })
      .then((resp) => {
        //console.log("data: " + JSON.stringify(resp.data));
        if (Array.isArray(resp.data.response.object)) {
          setList(resp.data.response.object);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const handleUpdate = () => {};
  const handleSave = () => {};
  const clientDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Client</DialogTitle>
        <DialogContent>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={numOrderState === "success"}
                  error={numOrderState === "error"}
                  labelText="Purchase Order number"
                  id="numPurchase"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "numPurchase",
                    onChange: (e) => handleInputChange(e.target),
                    value: numPurchase,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={netAmountState === "success"}
                  error={netAmountState === "error"}
                  labelText="Net Amount"
                  id="netAmount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "netAmount",
                    onChange: (e) => handleInputChange(e.target),
                    value: netAmount,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  success={numOfficeGuideState === "success"}
                  error={numOfficeGuideState === "error"}
                  labelText="Office Guide Number"
                  id="numOfficeGuide"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "numOfficeGuide",
                    onChange: (e) => handleInputChange(e.target),
                    value: numOfficeGuide,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  success={deliveryDateState === "success"}
                  error={deliveryDateState === "error"}
                  labelText="Delivery Date"
                  id="deliveryDate"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "email",
                    onChange: (e) => handleInputChange(e.target),
                    value: deliveryDate,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <CustomInput
                  labelText="Url"
                  id="web"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    autoComplete: "off",
                    name: "web",
                    onChange: (e) => handleInputChange(e.target),
                    value: web,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {!isUpdate ? (
            <Button color={"warning"} onClick={() => handleSave()}>
              Save Client
            </Button>
          ) : (
            <Button color={"warning"} onClick={() => handleUpdate()}>
              Update Client
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };
  const openModalClient = (isTrue = false) => {
    setIsUpdate(isTrue);
    setOpen(true);
  };
  const handleUpdateClick = (row) => {
    //openModalClient(true);
    console.log(row);
    return (
      <Link
        to="/admin/purchase-order"
        component={<PurchaseOrderNew row={row} />}
      />
    );
  };
  const handleRemoveClick = (id) => {
    axios
      .delete(endpoint + id, { headers: { "authorization-token": token } })
      .then((resp) => {
        if (resp.status === 200) {
          let res = list.filter((document) => document.id_documento !== id);
          setList(res);
          alert("Deleted Successfully");
        }
      });
  };
  return (
    <GridContainer>
      <GridItem>
        <Card>
          <CardHeader>
            <CardText color="info">
              <h4 className={classes.cardTitle}>List Purchase Order</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={8}></GridItem>
              <GridItem xs={4}>
                <Button
                  color="warning"
                  round
                  className={classes.marginLeft}
                  onClick={() => openModalClient(false)}
                >
                  Create Client
                </Button>
                {clientDialog()}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} md={12}>
                <ReactTable
                  columns={[
                    {
                      Header: "N° Order",
                      accessor: "num_doc",
                    },
                    {
                      Header: "Company",
                      accessor: "owner",
                    },
                    {
                      Header: "Fecha",
                      accessor: "fecha",
                    },
                    {
                      Header: "Employee",
                      accessor: "employee",
                    },
                    {
                      Header: "Net Amount",
                      accessor: "monto_neto",
                    },
                    {
                      Header: "Actions",
                      accessor: (row) => {
                        return (
                          <div className="actions-right">
                            <Button
                              justIcon
                              round
                              simple
                              onClick={() => handleUpdateClick(row)}
                              color="warning"
                              className="edit"
                            >
                              <Dvr />
                            </Button>
                            <Button
                              justIcon
                              round
                              simple
                              onClick={() =>
                                handleRemoveClick(row.id_documento)
                              }
                              color="danger"
                              className="remove"
                            >
                              <Close />
                            </Button>{" "}
                          </div>
                        );
                      },
                    },
                  ]}
                  data={list}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default PurchaseOrderList;
