import { types } from "lib/types";
import axios from "axios";

let endpoint = "/api/bff/purchase";

export const createPurchaseOrder = (purchase, listFiles, token) => {
  return async (dispatch) => {
    let created = {};
    await axios
      .post(
        endpoint,
        {
          files: listFiles,
          user: {
            id_user: purchase.employee.id,
            email: purchase.employee.name,
          },
          netAmount: purchase.netAmount,
          deliveryDate: purchase.delivery,
          numOrder: purchase.numOfficeGuide,
        },
        {
          headers: { "authorization-token": token },
        }
      )
      .then((result) => {
        console.log("result axios: " + JSON.stringify(result.data));
        created = result.data.created;
      })
      .catch((error) => {
        console.error("error: " + error);
      });
    if (created !== null && created !== undefined) {
      dispatch(createPurchase(true));
    } else {
      dispatch(createPurchase(false));
    }
  };
};
/*
export const deletePurchaseOrder = (id, token) => {
  console.log("action purchase");
  let res = {};
  return async (dispatch) => {
    await axios
      .delete("/api/bff/document/" + id, {
        headers: { "authorization-token": token },
      })
      .then((resp) => {
        console.log("data: " + resp.data);
        if (resp.status === 200) {
          console.log("200");
          res = res.data;
          console.log("200" + res);
        } else {
          res = resp;
          console.log(res);
        }
      })
      .catch((error) => {
        console.log("e: " + error);
        console.error(error);
      });
  };
};*/

export const updatePurchase = (id, purchase, token) => {
  axios
    .patch(
      endpoint + "/" + id,
      {
        numOrder: purchase.numOrder,
        netAmount: purchase.netAmount,
        delivery: purchase.deliveryDate,
        numOfficeGuide: purchase.numOfficeGuide,
      },
      { headers: { "authorization-token": token } }
    )
    .then((resp) => {
      console.log(resp);
    });
};

const createPurchase = (created) => ({
  type: types.createPurchase,
  payload: {
    created,
  },
});

/*const deletePurchase = (id, deleted) => ({
  type: types.deletePurchase,
  payload: {
    deleted,
    id_document: id,
  },
});*/
