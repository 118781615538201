export const types = {
  login: "[auth] login",
  logout: "[auth] logout",

  //admin reducer
  user: "[user] create",

  //company reducer
  createCompany: "[company] create",
  deleteCompany: "[company] delete",
  updateCompany: "[company] update",

  //User reducer
  createUser: "[company] create",
  deleteUser: "[company] delete",
  updateUser: "[company] update",

  //Employee reducer
  createEmployee: "[employee] create",
  saveEmployee: "[employee] save",
  roleAdmin: "ADMIN",

  //Purchase reducer
  createPurchase: "[purchase] create",
  deletePurchase: "[purchase] delete",
  updatePurchase: "[purchase] update",

  createError: "[error] create",
};
